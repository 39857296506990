.sandpack {
  font-family: Helvetica, sans-serif;
}
.sandpack * {
  box-sizing: border-box;
}

.sandpack-FileExplorer__container {
  background-color: #24282a;
  color: white;
  padding-top: 0.5em;
}

.sandpack-Navigator__container {
  display: flex;
  align-items: center;
  background-color: whitesmoke;
  width: 100%;
  padding: 0.5rem;
  border-radius: 2px;
  border-bottom: 1px solid #ddd;
}
.sandpack-Navigator__container:first-child {
  margin-left: 0;
}
.sandpack-Navigator__container:last-child {
  margin-left: 0;
}

.sandpack-Navigator__input {
  background-color: white;
  width: 100%;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 0.875rem;
  margin: 0 0.25rem;
  flex: 1;
}

.sandpack-Navigator__button {
  transition: 0.3s ease background-color;
  padding: 2px;
  margin: 0 0.25rem;
  font-size: 1.25rem;
  background-color: transparent;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  color: #727272;
  vertical-align: middle;
  cursor: pointer;
}
.sandpack-Navigator__button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.sandpack-TranspiledCodeView__container {
  position: relative;
  height: 100%;
}

.sandpack-TranspiledCodeView__error {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: Menlo, Source Code Pro, monospace;
  background-color: rgba(186, 53, 55, 0.7);
  color: white;
  padding: 0.5rem;
  z-index: 3;
}

/* CODEMIRROR STYLES */
/* BASICS */

.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  height: 300px;
  color: black;
  direction: ltr;
}

/* PADDING */

.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}
.CodeMirror pre {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}
.CodeMirror-linenumbers {
}
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: black;
}
.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* CURSOR */

.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
.cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
}
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  -webkit-animation: blink 1.06s steps(1) infinite;
  -moz-animation: blink 1.06s steps(1) infinite;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}
@-moz-keyframes blink {
  0% {
  }
  50% {
    background-color: transparent;
  }
  100% {
  }
}
@-webkit-keyframes blink {
  0% {
  }
  50% {
    background-color: transparent;
  }
  100% {
  }
}
@keyframes blink {
  0% {
  }
  50% {
    background-color: transparent;
  }
  100% {
  }
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {
}

.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: -20px;
  overflow: hidden;
}
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {
  color: blue;
}
.cm-s-default .cm-quote {
  color: #090;
}
.cm-negative {
  color: #d44;
}
.cm-positive {
  color: #292;
}
.cm-header,
.cm-strong {
  font-weight: bold;
}
.cm-em {
  font-style: italic;
}
.cm-link {
  text-decoration: underline;
}
.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}
.cm-s-default .cm-atom {
  color: #219;
}
.cm-s-default .cm-number {
  color: #164;
}
.cm-s-default .cm-def {
  color: #00f;
}
.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {
}
.cm-s-default .cm-variable-2 {
  color: #05a;
}
.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
  color: #085;
}
.cm-s-default .cm-comment {
  color: #a50;
}
.cm-s-default .cm-string {
  color: #a11;
}
.cm-s-default .cm-string-2 {
  color: #f50;
}
.cm-s-default .cm-meta {
  color: #555;
}
.cm-s-default .cm-qualifier {
  color: #555;
}
.cm-s-default .cm-builtin {
  color: #30a;
}
.cm-s-default .cm-bracket {
  color: #997;
}
.cm-s-default .cm-tag {
  color: #170;
}
.cm-s-default .cm-attribute {
  color: #00c;
}
.cm-s-default .cm-hr {
  color: #999;
}
.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error {
  color: #f00;
}
.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}
.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}
.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 30px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -30px;
  margin-right: -30px;
  padding-bottom: 30px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
}
.CodeMirror-sizer {
  position: relative;
  border-right: 30px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
}
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -30px;
}
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}
.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}
.CodeMirror pre {
  /* Reset some styles that the rest of the page might have set */
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
}
.CodeMirror-wrap pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-widget {
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}
.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}
.CodeMirror-crosshair {
  cursor: crosshair;
}
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}
.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: '';
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

/*

    Name:       oceanic-next
    Author:     Filype Pereira (https://github.com/fpereira1)

    Original oceanic-next color scheme by Dmitri Voronianski (https://github.com/voronianski/oceanic-next-color-scheme)

*/

.cm-s-oceanic-next.CodeMirror {
  background: #304148;
  color: #f8f8f2;
}
.cm-s-oceanic-next div.CodeMirror-selected {
  background: rgba(101, 115, 126, 0.33);
}
.cm-s-oceanic-next .CodeMirror-line::selection,
.cm-s-oceanic-next .CodeMirror-line > span::selection,
.cm-s-oceanic-next .CodeMirror-line > span > span::selection {
  background: rgba(101, 115, 126, 0.33);
}
.cm-s-oceanic-next .CodeMirror-line::-moz-selection,
.cm-s-oceanic-next .CodeMirror-line > span::-moz-selection,
.cm-s-oceanic-next .CodeMirror-line > span > span::-moz-selection {
  background: rgba(101, 115, 126, 0.33);
}
.cm-s-oceanic-next .CodeMirror-gutters {
  background: #304148;
  border-right: 10px;
}
.cm-s-oceanic-next .CodeMirror-guttermarker {
  color: white;
}
.cm-s-oceanic-next .CodeMirror-guttermarker-subtle {
  color: #d0d0d0;
}
.cm-s-oceanic-next .CodeMirror-linenumber {
  color: #d0d0d0;
}
.cm-s-oceanic-next .CodeMirror-cursor {
  border-left: 1px solid #f8f8f0;
}

.cm-s-oceanic-next span.cm-comment {
  color: #65737e;
}
.cm-s-oceanic-next span.cm-atom {
  color: #c594c5;
}
.cm-s-oceanic-next span.cm-number {
  color: #f99157;
}

.cm-s-oceanic-next span.cm-property {
  color: #99c794;
}
.cm-s-oceanic-next span.cm-attribute,
.cm-s-oceanic-next span.cm-keyword {
  color: #c594c5;
}
.cm-s-oceanic-next span.cm-builtin {
  color: #66d9ef;
}
.cm-s-oceanic-next span.cm-string {
  color: #99c794;
}

.cm-s-oceanic-next span.cm-variable,
.cm-s-oceanic-next span.cm-variable-2,
.cm-s-oceanic-next span.cm-variable-3 {
  color: #f8f8f2;
}
.cm-s-oceanic-next span.cm-def {
  color: #6699cc;
}
.cm-s-oceanic-next span.cm-bracket {
  color: #5fb3b3;
}
.cm-s-oceanic-next span.cm-tag {
  color: #c594c5;
}
.cm-s-oceanic-next span.cm-header {
  color: #c594c5;
}
.cm-s-oceanic-next span.cm-link {
  color: #c594c5;
}
.cm-s-oceanic-next span.cm-error {
  background: #c594c5;
  color: #f8f8f0;
}

.cm-s-oceanic-next .CodeMirror-activeline-background {
  background: rgba(101, 115, 126, 0.33);
}
.cm-s-oceanic-next .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

/* SANDPACK STYLES */
.sandpack-CodeMirror__container {
  position: relative;
  height: inherit;
  width: inherit;
  background: #1c2022;
}
.sandpack-CodeMirror__container .CodeMirror {
  font-family: 'Menlo', 'Source Code Pro', 'monospace';
  line-height: 1.6;
  background: #1c2022;
  color: #e0e0e0;
  height: 100%;
  font-weight: 500;
}
.sandpack-CodeMirror__container div.CodeMirror-selected {
  background: #374140;
}
.sandpack-CodeMirror__container .CodeMirror-line::selection,
.sandpack-CodeMirror__container .CodeMirror-line > span::selection,
.sandpack-CodeMirror__container .CodeMirror-line > span > span::selection {
  background: #65737e;
}
.sandpack-CodeMirror__container .CodeMirror-line::-moz-selection,
.sandpack-CodeMirror__container .CodeMirror-line > span::-moz-selection,
.sandpack-CodeMirror__container .CodeMirror-line > span > span::-moz-selection {
  background: #65737e;
}
.sandpack-CodeMirror__container .CodeMirror-gutters {
  background: #1c2022;
  border-right: 0px;
}
.sandpack-CodeMirror__container .CodeMirror-guttermarker {
  color: #ac4142;
}
.sandpack-CodeMirror__container .CodeMirror-guttermarker-subtle {
  color: #505050;
}
.sandpack-CodeMirror__container .CodeMirror-linenumber {
  color: #505050;
}
.sandpack-CodeMirror__container .CodeMirror-cursor {
  border-left: 1px solid #b0b0b0;
}
.sandpack-CodeMirror__container span.cm-comment {
  color: #626466;
}
.sandpack-CodeMirror__container span.cm-atom {
  color: #aa759f;
}
.sandpack-CodeMirror__container span.cm-number {
  color: #aa759f;
}
.sandpack-CodeMirror__container span.cm-property,
.sandpack-CodeMirror__container span.cm-attribute {
  color: #aa759f;
}
.sandpack-CodeMirror__container span.cm-keyword {
  color: #6caedd;
}
.sandpack-CodeMirror__container span.cm-string {
  color: #99c794;
}
.sandpack-CodeMirror__container span.cm-variable {
  color: #ffd399;
}
.sandpack-CodeMirror__container span.cm-variable-2 {
  color: #6caedd;
}
.sandpack-CodeMirror__container span.cm-def {
  color: #fac863;
}
.sandpack-CodeMirror__container span.cm-bracket {
  color: #e0e0e0;
}
.sandpack-CodeMirror__container span.cm-tag {
  color: #ec5f67;
}
.sandpack-CodeMirror__container span.cm-link {
  color: #aa759f;
}
.sandpack-CodeMirror__container span.cm-error {
  background: #ac4142;
  color: #b0b0b0;
}
.sandpack-CodeMirror__container .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.2);
}
.sandpack-CodeMirror__container .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
.sandpack-CodeMirror__container span.CodeMirror-matchingtag {
  background-color: inherit;
}
.sandpack-CodeMirror__container span.cm-tag.CodeMirror-matchingtag {
  text-decoration: underline;
}
.sandpack-CodeMirror__container span.cm-tag.cm-bracket.CodeMirror-matchingtag {
  text-decoration: none;
}
.sandpack-CodeMirror__container
  div.cm-line-highlight.CodeMirror-linebackground {
  background-color: rgba(0, 0, 0, 0.3);
}

.sandpack-File__container {
  transition: 0.3s ease all;
  font-family: sans-serif;
  font-size: 0.875em;
  padding: 0.3em 0.5em;
  padding-left: 1rem;
  color: gainsboro;
  border-left: 2px solid transparent;
  cursor: pointer;
}
.sandpack-File__container:hover {
  color: white;
}

.sandpack-File__active {
  background-color: rgba(0, 0, 0, 0.3);
  border-left: 2px solid #6caedd;
}
